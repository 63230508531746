import React from 'react'

import { Box, Button, Calendar, Text, TextArea, TextInput, Stack, Image, ResponsiveContext } from 'grommet'

import Login from "../Login/Login"

import { getAuth } from "firebase/auth";


const Contact = () => {

    const [isLoggedIn, setIsloggedIn] = React.useState();

    getAuth().onAuthStateChanged((user) => {
        return user ? setIsloggedIn(true) : setIsloggedIn(false);
    })

    return (
        <Box

        >
            {!isLoggedIn ? (
                <Login />
            ) : (
                <Text>Admin</Text>
            )}


        </Box>
    )
}

export default Contact
