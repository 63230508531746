import React from 'react'

import { Box, Stack, Text, Image, ResponsiveContext } from 'grommet'
import Lottie from 'react-lottie'

import fond from '../../assets/images/fond.png'
import { Location, Video } from 'grommet-icons'



const Banner = () => {

    const size = React.useContext(ResponsiveContext);

    return (

        <Box
            fill="horizontal"
        >

            <Stack anchor="center" fill="horizontal">
                <Box overflow="hidden" height="large">
                    <Image src={fond} fit="cover" />
                </Box>


                <Box

                    direction={size === "small" ? "column" : "row"}
                    justify='between'
                    gap="medium"
                    align="center"
                    width='large'
                    height={size !== "small" ? "medium" : "small"}>

                    <Box>
                        <Lottie
                            options={{ path: "https://assets10.lottiefiles.com/packages/lf20_j2rjqphu.json" }}
                            height={300}
                            width={300}
                        />
                    </Box>

                    <Box align='start'>
                        <Text size="large" weight='bold' color='secondary'>Cours particuliers Barbara</Text>
                        <Text color='secondary'>La réussite scolaire à portée de crayon</Text>
                        <Box direction='row' align='center' justify='center' margin={{ top: "medium" }} gap="small">
                            <Location />
                            <Text color='title'>En présentiel sur Toulouse</Text>
                        </Box>
                        <Box direction='row' align='center' justify='center' margin={{ top: "small" }} gap="small">
                            <Video />
                            <Text color='title'>En distanciel</Text>
                        </Box>
                    </Box>
                </Box>

            </Stack>

        </Box>
    )
}

export default Banner
