import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import { initializeApp } from 'firebase/app';

var firebaseConfig = {
  apiKey: "AIzaSyD5mgH0V1TLKs2Nl376sdYl5FF_ZWXiNhk",
  authDomain: "prof-barbara-ac2ec.firebaseapp.com",
  projectId: "prof-barbara-ac2ec",
  storageBucket: "prof-barbara-ac2ec.appspot.com",
  messagingSenderId: "244624880443",
  appId: "1:244624880443:web:306def94874f62398bfd1e",
  measurementId: "G-1C0EEMXKDW"
};

const app = initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
