import React from 'react'

import { ResponsiveContext, Box, Text } from 'grommet'
import { Group, User } from 'grommet-icons';

const Pricing = () => {

    const size = React.useContext(ResponsiveContext);

    return (
        <Box gap='small' align='center'>

            <Text color='secondary' size="large" weight='bold' margin="small">Trouvez la formule qui vous convient</Text>


            <Box align='center' justify='center' margin={{ top: 'large' }} direction={size == 'small' ? 'column' : 'row'} gap="small" overflow='auto' pad="small">

                <Box gap="medium" align='center' justify="center" round="small" background="white" pad="medium" width='medium'>
                    <User />
                    <Text color='secondary' weight='bold'>Cours Particulier (1 élève)</Text>
                    <Text color='secondary' textAlign='center'>20€/h</Text>
                </Box>

                <Box gap="medium" align='center' justify="center" round="small" background="white" pad="medium" width='medium'>
                    <Box direction='row' gap='xsmall' >
                        <User /><User />
                    </Box>
                    <Text color='secondary' weight='bold'>Cours Duo (2 élèves)</Text>
                    <Text color='secondary' textAlign='center'>30€/h soit 15€/h par élève</Text>
                </Box>

                <Box gap="medium" align='center' justify="center" round="small" background="white" pad="medium" width='medium'>
                    <Group />
                    <Text color='secondary' weight='bold'>Cours Trio (3 élèves) </Text>
                    <Text color='secondary' textAlign='center'>30€/h soit 10€/h par élève</Text>
                </Box>

            </Box>
        </Box>
    )
}

export default Pricing
