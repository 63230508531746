import React from 'react'

import { Box, Image, Text } from 'grommet'

import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'

const Footer = () => {



    return (
        <Box align='center' justify='center' gap="large" margin="large" >
            <Box direction='row' align='center' justify='center' gap="large">
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <Box animation='slideRight'>
                        <Text weight='bold' color='title'>Accueil</Text>
                    </Box>
                </Link>
                {/* <Link to='/' style={{ textDecoration: 'none' }}>
                    <Box animation='slideRight'>
                        <Text weight='bold' color='title'>Fiches</Text>
                    </Box>
                </Link> */}

                <Link to='/about' style={{ textDecoration: 'none' }}>
                    <Box animation="slideLeft">
                        <Text weight='bold' color='title'>A propos</Text>
                    </Box>
                </Link>
            </Box>

            <Text>Prof Barbara </Text>
        </Box>
    )
}

export default Footer
