import React from 'react'

import { Box, Image, Text } from 'grommet'

import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'

const Header = () => {



    return (
        <Box direction='column' align='center' justify='center' gap="medium" >
            {/* <Link to='/' style={{ textDecoration: 'none' }}>
                <Box animation='slideRight'>
                    <Text weight='bold' color='title'>Fiches</Text>
                </Box>
            </Link> */}
            <Link to='/'>
                <Box width='small' height='small' animation='slideDown'>
                    <Image src={logo} fit='contain'></Image>
                </Box>
            </Link>
            <Link to='/about' style={{ textDecoration: 'none' }}>
                <Box animation="slideLeft">
                    <Text weight='bold' color='title'>A propos</Text>
                </Box>
            </Link>
        </Box>
    )
}

export default Header
