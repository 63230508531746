import React from "react";

import { Box, TextInput, Button, Text } from "grommet"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
    const [email, setemail] = React.useState();
    const [password, setpassword] = React.useState();
    const [error, seterror] = React.useState();


    const handleSubmit = (e) => {
        e.preventDefault();

        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password).catch((error) => {
            seterror("Identifiants incorrects");
        })

    }

    return (



        <Box gap="small" align='center' margin="medium">
            Login

            <Box width="small" gap="small">
                <TextInput type="email" placeholder="email" onChange={(e) => setemail(e.target.value)} />
                <TextInput type="password" placeholder="password" onChange={(e) => setpassword(e.target.value)} />
            </Box>

            {error && (
                <Text margin="small" color="red">{error}</Text>
            )}

            <Button label="Se connecter" color="brand" onClick={(e) => handleSubmit(e)} />

        </Box>
    )
}


export default Login;