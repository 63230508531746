import React from 'react'

import { Box, Text, Image } from 'grommet'
import barbara from "../../assets/images/barbara1.jpeg"


const About = () => {

    

    return (
        <Box align='center' justify='center' margin='xlarge' fill>


            <Box round="full" width="small" height="small" overflow="hidden">
                <Image src={barbara} fit="cover" />
            </Box>

            <Box width="large" gap='medium' margin="medium">
                <Text margin="small" color="secondary" size='large' weight='bold'>Je m'appelle Barbara, j'ai 28 ans.</Text>
                <Box>

                    <Text margin="small" color="secondary" size='large'>
                        J’ai obtenu mon Bac S mention TB en 2013. J’ai ensuite poursuivi mes études à l’INSA de
                        Toulouse en me spécialisant dans les maths et l’informatique puis plus précisément en
                        cybersécurité.
                    </Text>
                    <Text margin="small" color="secondary" size='large'>
                        À la suite de l’obtention de mon diplôme d’ingénieure, j’ai intégré le service de lutte contre la
                        fraude et les attaques informatiques d’un grand groupe bancaire. Durant mes 3 années à ce
                        poste (1 an d’alternance et 2 ans de CDI), j’ai créé et animé différentes sensibilisations et
                        formations autour de sujets tels que le développement sécurisé, les risques du phishing (ou
                        hameçonnage) ou plus généralement les bonnes pratiques de sécurité informatique. Plusieurs
                        formats ont été explorés : escape game, quizz, formation « classique »... Ces sensibilisations
                        ayant des publics avec différents niveaux de connaissance en informatique, j’ai souvent dû
                        adapter le contenu pour convenir aux destinataires de la session.
                    </Text>
                </Box>
            </Box>

            <Box width="large" gap='medium' margin="medium">
                <Box>
                    <Text margin="small" color="secondary" size='large'>En parallèle, avec 5 autres camarades de l’INSA, nous avons créé une entreprise de pédagogie
                        innovante autour de la sécurité informatique. Nous avons développé 2 scénarios d’escape
                        game complets pour enseigner les réflexes de base en sécurité informatique ainsi qu’un jeu de
                        piste en ligne. Plusieurs sessions ont été organisées aussi bien pour des entreprises que pour
                        des écoles supérieures.</Text>

                </Box>
            </Box>

            <Box width="large" gap='medium' margin="medium">
                <Box>
                    <Text margin="small" color="secondary" size='large'>
                        De plus, ayant vécu à l’étranger une bonne partie de mon enfance (Mexique et Pays-Bas), je suis trilingue anglais et espagnol.
                    </Text>
                </Box>
            </Box>

            <Box width="large" gap='medium' margin="medium">
                <Box>
                    <Text margin="small" color="secondary" size='large'>
                        Tout au long de mes études, j’ai donné des cours particuliers de maths, sciences, anglais et espagnol (niveau collège/lycée) via la plateforme LiveMentor notamment. Les cours étaient principalement à distance en visio mais j’ai également donné des cours en physique.
                        J’ai toujours eu une passion pour la pédagogie et l’enseignement. J’aime diversifier les méthodes d’apprentissage et les supports (audio, vidéo, exercices personnalisés, fiches...) pour trouver ce qui conviendra le mieux à mon élève. Il est également important pour moi que les élèves se sentent en confiance avec moi en créant un espace de travail sécurisant, inclusif, créatif et bienveillant.
                    </Text>
                </Box>
            </Box>



        </Box>
    )
}

export default About
