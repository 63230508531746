import React from 'react'

import { Box, Text, Image, ResponsiveContext } from 'grommet'
import { Link } from 'react-router-dom'

import barbara from "../../assets/images/barbara1.jpeg"

const Portrait = () => {

    const size = React.useContext(ResponsiveContext);

    return (
        <Box align='center' justify='center' >

            <Box justify='between' width="large" align="center" gap="large">
                <Link to='/about'>
                    <Box round="full" width={size === "small" ? "small" : "medium"} height={size === "small" ? "small" : "medium"} overflow="hidden">
                        <Image src={barbara} fit="cover" />
                    </Box>

                </Link>
                <Box width="large">
                    <Text color="secondary" margin="small" weight="bold">Je m'appelle Barbara, j'ai 28 ans.</Text>
                    <Text color="secondary" margin="small" >
                        Diplômée ingénieure en informatique et forte de mes expériences de formation dans différents
                        milieux (entreprise, école, cours particuliers), je donne aujourd’hui des cours de sciences,
                        mathématiques et langues (espagnol et anglais) pour le collège et lycée. J’ai développé différentes formes de
                        pédagogie (escape game, quizz, vidéos, fiches, exercices personnalisés...) afin de toujours
                        m’adapter aux élèves et avoir des conditions d’apprentissage optimales.
                    </Text>
                </Box>

            </Box>

        </Box>
    )
}

export default Portrait
