import React from 'react'

import { Box } from 'grommet'

import Banner from '../banner/Banner'
import Sheets from '../Sheets/Sheets'
import Method from '../method/Method'
import Contact from '../Contact/Contact'
import Portrait from '../Portrait/Portrait'
import Pricing from '../Pricing/Pricing'


const Home = () => {



    return (

        <Box fill overflow="auto" >

            <Banner />
            <Portrait />
            <Method />
            {/* <Sheets /> */}


            <Contact />
            <Pricing />

        </Box>
    )
}

export default Home
