import React, { useState } from 'react'

import { Box, Text, Layer, Button, ResponsiveContext } from 'grommet'

import Lottie from 'react-lottie'
import { Close } from 'grommet-icons'

const Method = () => {

    const [index, setindex] = useState(1)
    const [show, setshow] = useState(false)

    const size = React.useContext(ResponsiveContext);

    const data = [{
        title: "Étape 1 : prise de contact",
        content: "Avant de commencer les cours, il est important de définir ensemble les besoins et attentes de l’élève. Cette étape peut se dérouler par téléphone ou par écrit. Il s’agit d’une discussion autour du parcours de l’élève, ses difficultés, sa méthode de travail actuelle, le rythme d’accompagnement souhaité... C’est également le moment pour l’élève de me poser toutes les questions nécessaires pour établir une relation de confiance."
    },
    {
        title: "Étape 2 : définition de l’accompagnement",
        content: "Je défini une méthodologie de travail comprenant : la fréquence de cours, le type d’accompagnement (aide aux devoirs, reprise de cours, organisation...), cours individuels ou en petit groupe, à distance ou en présentiel. Bien sûr, rien n’est figé et la méthodologie de travail peut être amenée à évoluer au cours de la durée de l’accompagnement et des besoins de l’élève."
    },
    {
        title: "Étape 3 : démarrage des cours",
        content: "Les cours se déroulent selon la méthodologie définie. Je m’engage à établir une ambiance de cours bienveillante et inclusive où les élèves se sentent en confiance et l’apprentissage est favorisé. Tous les supports de cours seront envoyés à l’élève à la fin de la séance ainsi que des fiches résumé et corrections propres des exercices."
    }]

    return (
        <Box align='center' justify='center' margin={{ top: 'large' }}>
            <Text color='secondary' size="large" weight='bold' margin="small">Une méthode adaptative, inclusive et efficace</Text>
            <Text color="title" size="small">Cliquez sur les étapes pour en savoir plus</Text>
            <Box margin='medium' direction={size === 'small' ? 'column' : 'row'} justify='between' gap="medium">

                <Lottie
                    options={{ path: "https://assets7.lottiefiles.com/packages/lf20_arirrjzh.json" }}
                    height={300} />

                <Box>
                    {data.map((m, i) => (

                        <Box align={i === 1 ? 'start' : 'end'}>
                            <Box
                                onClick={() => {
                                    setindex(i)
                                    setshow(true)
                                }}
                            >
                                <Text color="title">{m.title}</Text>

                            </Box>
                            {i !== 2 && (
                                <Lottie
                                    options={{

                                        path: 'https://assets7.lottiefiles.com/packages/lf20_j3s9ee26.json'
                                    }}
                                    style={i === 1 && { transform: `scaleX(-1` }}
                                    height={100}
                                />
                            )}


                        </Box>



                    ))}

                </Box>

                {show && (
                    <Layer
                        modal='false'
                        margin="medium"

                        onEsc={() => setshow(false)}
                        onClickOutside={() => setshow(false)}>
                        <Box background="white" width="large" pad="small" round="small" gap="small">

                            <Box fill="horizontal" align="end">
                                <Button icon={<Close />} onClick={() => setshow(false)} />
                            </Box>


                            <Text color='title'>{data[index].title}</Text>
                            <Text color='secondary'>{data[index].content}</Text>
                        </Box>
                    </Layer>
                )}


            </Box>
        </Box>
    )
}

export default Method
