import React from 'react'

import { Box, Button, Calendar, Text, TextArea, TextInput, Stack, Image, ResponsiveContext } from 'grommet'

import fond from '../../assets/images/fond-reverse.png'


const Contact = () => {


    return (
        <Box

        >
            <Stack anchor="center">
                <Box overflow="hidden" height="large">
                    <Image src={fond} fit="cover" />
                </Box>

                <Box align='center' justify='center' pad="medium" >
                    <Text color='secondary' size="large" weight='bold' margin="small">Prenez rendez-vous dès maintenant :</Text>
                    <Text color="title" size="small">Le premier rendez-vous est gratuit !</Text>
                    {/* <Box margin='medium' direction='row' gap="medium">
                        <Box gap="small">
                            <TextInput placeholder="Nom" />
                            <TextInput placeholder="Email" />
                            <TextArea size="small" placeholder="Quels sont vos besoins?"></TextArea>
                        </Box>
                        <Box>
                            <Calendar size="small" />
                        </Box>
                    </Box>

                    <Button primary label='Prendre rendez-vous'></Button> */}

                    <Box margin="medium" gap="medium">
                        <Text>barbara.joannes@gmail.com</Text>

                    </Box>
                </Box>
            </Stack>
        </Box>
    )
}

export default Contact
