import React from "react";
import { Grommet, Box, } from "grommet";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './features/home/Home'
import Header from './features/header/Header'
import About from './features/About/About'
import Footer from "./features/Footer/Footer";
import Admin from "./features/Admin/Admin"



const theme = {
  global: {
    colors: {
      secondary: '#a83248', // redish
      brand: "#7adcf0", // blue
      title: "#eca26f", // pink/orange

    },
    font: {
      family: "Verdana",
      size: "18px",
      height: "20px",
    },
  },
};

function App() {
  return (
    <Grommet theme={theme}>
      <Box fill align="center" justify="center" background="brand" >

        <Router>
          <Header />
          <Route path='/' exact>
            <Home />
          </Route>
          <Route path='/about' exact>
            <About />
          </Route>
          <Route path='/admin' exact>
            <Admin />
          </Route>
          <Footer />
        </Router>
      </Box>

    </Grommet>
  );
}

export default App;
